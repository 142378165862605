$global: #09278f;
$selected-bg: rgba(9, 39, 143, 0.5);
$hover-bg: rgba(9, 39, 143, 0.08);
$hover-bg-no-opacity: #e8ebf5;
$black: #000000;
$white: #ffffff;
$orange: rgb(255, 146, 46);
$text: #1c1c1c;
$text-breadcrumb: #595959;
$text-placeholder: #969696;
$icon: $global;
$input-bg: #f2f2f2;
$main-bg: #fafafa;
$border: #dcdcdc;
$label-blue: #8e9fdb;
$label-purple: #ab8edb;
$label-pink: #db8eca;
$label-red: #db8e8e;
$label-orange: #eea876;
$label-yellow: #babc4e;
$label-green: #78bc4e;
$label-emerald: #4ebcbc;
$label-sky: #6fa4d6;
$label-gray: #888888;
$label-silver: #bebebe;
$label-bright: #f2f2f2;
$divider: rgba(0, 0, 0, 0.12);
$appointment-timeline-odd-bg-gray: #fafafa;
$appointment-event-pref-bg-lavender: #cdcdff;
$appointment-event-pref-bg-sage: #c9f0df;
$appointment-event-pref-bg-grape: #e6b9f3;
$appointment-event-pref-bg-flamingo: #f3bdb9;
$appointment-event-pref-bg-banana: #fde8b2;
$appointment-event-pref-bg-orange: #f9a88e;
$appointment-event-pref-bg-peacock: #cff0f3;
$appointment-event-pref-bg-graphite: #e1e1e1;
$appointment-event-pref-bg-blueberry: #a4c3f3;
$appointment-event-pref-bg-basil: #8ad3b2;
$appointment-event-pref-bg-tomato: #ea8080;
$appointment-event-pref-bg-cocoa: #d5b8ae;
$appointment-event-pref-bg-lemon: #fdf4c1;
$appointment-event-pref-bg-avocado: #d9edb5;
$appointment-event-pref-bg-white: #ffffff;

// 画面の高さ - global header - main margin - main header
$calculated-space-y: calc(100vh - 50px - 48px - 74px);
// 画面の横幅 - side bar - main margin
$calculated-space-x: calc(100vw - 50px - 48px);
